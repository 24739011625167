import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
// import { immer } from "zustand/middleware/immer";
import { persist, createJSONStorage } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { mergeDeepLeft } from "ramda";
// import devDebug from "../Helpers/debug";
import { get, set, del } from "idb-keyval"; // can use anything: IndexedDB, Ionic Storage, etc.

const storage = {
  getItem: async (name) => {
    //devDebug(name, "has been retrieved");
    return (await get(name)) || null;
  },
  setItem: async (name, value) => {
    //devDebug(name, "with value", value, "has been saved");
    await set(name, value);
  },
  removeItem: async (name) => {
    //devDebug(name, "has been deleted");
    await del(name);
  },
};

const tenderSearchTabAdminKey = `${process.env.REACT_APP_TENDER_SEARCH_TAB_ADMIN_KEY}_${process.env.REACT_APP_VERSION}`;
const tenderSearchTabKey = `${process.env.REACT_APP_TENDER_SEARCH_TAB_KEY}_${process.env.REACT_APP_VERSION}`;
const tenderAwardsSearchTabKey = `${process.env.REACT_APP_AWARDS_SEARCH_TAB_KEY}_${process.env.REACT_APP_VERSION}`;

let tab = {};

tab[tenderAwardsSearchTabKey] = 0;
tab[tenderSearchTabAdminKey] = 0;
tab[tenderSearchTabKey] = 0;

const initialState = {
  tab: tab,
  randomReinit: Math.random(),
  tenderStrategies: [],
  tenderStrategiesLoaded: false,
  applyToAllTabs: false,
  searchViewMode: "schedule",
  scheduleViewModalVisible: false,
  userChoseView: false,
  tenders: [],
  tendersFiltered: [],
  tendersFull: {
    total_pages: 1,
    results: [],
  },
  refreshingTable: true,
  goToFileUploadAddTender: false,
  strategyFilterID: 0,
  checkedAutoConfirmCheckBoxes: [],
};

export const useTenderSearchStore = createWithEqualityFn(
  persist(
    immer((set, get) => ({
      ...initialState,
      setHasHydrated: (state) => {
        set({
          _hasHydrated: state,
        });
      },

      // ------------------------- AUTOCONFIRM -------------------------

      checkUncheckAutoConfirmCheckBox: (id) =>
        set((state) => {
          if (state.checkedAutoConfirmCheckBoxes.includes(id)) {
            state.checkedAutoConfirmCheckBoxes = state.checkedAutoConfirmCheckBoxes.filter((x) => x !== id);
          } else {
            state.checkedAutoConfirmCheckBoxes = [...state.checkedAutoConfirmCheckBoxes, id];
          }
        }),
      removeAutoConfirmCheckBox: (id) =>
        set((state) => {
          state.checkedAutoConfirmCheckBoxes = state.checkedAutoConfirmCheckBoxes.filter((x) => x !== id);
        }),
      resetAutoConfirmCheckBoxes: () =>
        set((state) => {
          state.checkedAutoConfirmCheckBoxes = [];
        }),

      // ------------------------- OTHERS ------------------------------

      setStrategyFilterID: (strategyFilterID, strat) =>
        set((state) => {
          state.strategyFilterID = strategyFilterID;
          if (!strat?.cpvs) {
            state.tendersFiltered = state.tenders;
          } else {
            let filteredTenders = [];
            for (let tender of state.tenders) {
              let includeCPV = false;
              for (let cpv of tender.cpv) {
                if (strat.cpvs.includes(cpv)) {
                  includeCPV = true;
                  break;
                }
              }
              if (strat.nuts.includes(tender.nut_code) && includeCPV) {
                filteredTenders.push(tender);
                continue;
              }
            }
            state.tendersFiltered = filteredTenders;
          }
        }),

      setGoToFileUploadAddTender: (goToFileUploadAddTender) =>
        set((state) => {
          state.goToFileUploadAddTender = goToFileUploadAddTender;
        }),
      setRefreshingTable: (refreshingTable) =>
        set((state) => {
          state.refreshingTable = refreshingTable;
        }),
      setTenders: (tenders) =>
        set((state) => {
          state.tendersFull =
            tenders?.results?.length > 0
              ? tenders
              : {
                total_pages: 1,
                results: [],
              };
          state.tenders = tenders?.results?.length > 0 ? tenders.results : [];
          state.tendersFiltered = tenders?.results?.length > 0 ? tenders.results : [];
        }),
      setUserChoseView: (userChoseView) =>
        set((state) => {
          state.userChoseView = userChoseView;
        }),
      settableQueryParams: (payload) =>
        set((state) => {
          state.tableQueryParams = payload;
        }),
      setScheduleViewModalVisible: (scheduleViewModalVisible) =>
        set((state) => {
          state.scheduleViewModalVisible = scheduleViewModalVisible;
        }),
      setSearchViewMode: (mode) =>
        set((state) => {
          state.searchViewMode = mode;
        }),
      setTab: (payload, section) =>
        set((state) => {
          state.tab[section] = payload;
        }),
      setRandomReinit: () =>
        set((state) => {
          state.randomReinit = Math.random();
        }),
      setApplyToAllTabs: (payload) =>
        set((state) => {
          state.applyToAllTabs = payload;
        }),
      setTenderStrategies: (tabName, payload) =>
        set((state) => {
          state.tenderStrategies = payload;
          const actualTab = { ...state.tab };
          if (actualTab[tabName] >= state.tenderStrategies.length) {
            state.tab[tabName] = 0;
          }
        }),
      setTenderStrategiesLoaded: (payload) =>
        set((state) => {
          state.tenderStrategiesLoaded = payload;
        }),
      resetState: () => set(initialState),
    })),
    {
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) =>
              !["_hasHydrated", "input", "tenderStrategies", "hideListNutsSearch", "tenderStrategiesLoaded"].includes(
                key
              )
          )
        ),
      name: "tenderSearch",
      storage: createJSONStorage(() => storage),
      version: process.env.REACT_APP_VERSION,
      merge: (persistedState, currentState) => mergeDeepLeft(persistedState, currentState),
      onRehydrateStorage: (state) => {
        // devDebug("tender search store -> hydration starts, state: ", state);
        return (state, error) => {
          if (error) {
            // devDebug(
            //   "tender search store -> an error happened during hydration",
            //   error
            // );
          } else {
            // devDebug("tender search store -> hydration finished, state: ", state);
            state.setHasHydrated(true);
          }
        };
      },
    }
  ),
  shallow
);
