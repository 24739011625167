import React from "react";
import { slide as Menu } from "react-burger-menu";
import TendersClubLogo from "../../Components/tendersClubLogo";
import { useAuth } from "../../Services/auth";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SidebarContext } from "../sidebarContext";
import AwardsSearchDropdown from "./awardsSearchDropdown";
import TendersDropdown from "./tendersDropdown";

export default function Navigation({ sidebar }) {
  const auth = useAuth();
  const ctx = React.useContext(SidebarContext);
  let authenticated = false;
  if (auth.user) {
    authenticated = true;
  }

  return sidebar ? (
    <Menu
      right
      customBurgerIcon={false}
      isOpen={ctx.isMenuOpen}
      onStateChange={(state) => ctx.stateChangeHandler(state)}
      width={320}
    >
      <div className="d-flex flex-column tc-flex-gap-10px">
        <NavLinks sidebar={sidebar} ctx={ctx} authenticated={authenticated} auth={auth} />
        {authenticated ? <AwardsSearchDropdown sidebar={sidebar} ctx={ctx} /> : null}
      </div>
    </Menu>
  ) : (
    <div className={`tc-horizontal-nav ${authenticated ? "authenticated" : "w-full"}`}>
      <NavLinks sidebar={sidebar} ctx={ctx} authenticated={authenticated} auth={auth} />
    </div>
  );
}

export const shortenItem = (item, sidebar) => {
  if (item.length > 25 && sidebar) {
    return item.slice(0, 25) + "...";
  }
  return item;
};

const NavLinks = ({ sidebar, ctx, authenticated, auth }) => {
  const { t, i18n } = useTranslation();

  const menuActions = () => {
    ctx.toggleMenu(false);
  };

  return (
    <React.Fragment>
      {sidebar ? (
        <NavLink onClick={() => ctx.toggleMenu(false)} to="/" className="logo logo-light">
          <TendersClubLogo size="normal" colour="light" />
        </NavLink>
      ) : null}
      {authenticated ? (
        <a
          href={`https://blog.tenders.club/${i18n.language.slice(0, 2)}`}
          className={`menu-item ${sidebar ? "menu-item-sidebar" : ""}`}
          target="_blank"
          rel="noreferrer"
        >
          {shortenItem(t("Blog"), sidebar)}
        </a>
      ) : null}
      <NavLink
        onClick={() => menuActions()}
        to="/pricing"
        className={`menu-item ${sidebar ? "menu-item-sidebar" : ""}`}
      >
        {shortenItem(t("Pricing"), sidebar)}
      </NavLink>
      {authenticated ? (
        <TendersDropdown sidebar={sidebar} ctx={ctx} />
      ) : (
        <NavLink
          onClick={() => menuActions()}
          to="/search"
          className={`menu-item ${sidebar ? "menu-item-sidebar" : ""}`}
        >
          {shortenItem(t("Search Tenders"), sidebar)}
        </NavLink>
      )}
      <NavLink
        onClick={() => menuActions()}
        to="/contact"
        className={`menu-item ${sidebar ? "menu-item-sidebar" : ""}`}
      >
        {shortenItem(t("Contact"), sidebar)}
      </NavLink>
      {authenticated ? null : (
        <NavLink
          onClick={() => menuActions()}
          to="/login"
          className={`menu-item ${!sidebar ? " ms-auto" : " menu-item-sidebar"}`}
        >
          {shortenItem(t("Login"), sidebar)}
        </NavLink>
      )}
      {authenticated ? null : (
        <NavLink
          onClick={() => menuActions()}
          to="/register"
          className={`menu-item ${sidebar ? "menu-item-sidebar" : ""}`}
        >
          {shortenItem(t("Register"), sidebar)}
        </NavLink>
      )}
      {authenticated && sidebar ? (
        <NavLink
          onClick={() => menuActions()}
          to="/user_preferences"
          className={`menu-item ${sidebar ? "menu-item-sidebar" : ""}`}
        >
          {shortenItem(t("Settings"), sidebar)}
        </NavLink>
      ) : null}
      {authenticated ? (
        <NavLink
          onClick={() => menuActions()}
          to="/tender_strategy"
          className={`menu-item ${sidebar ? "menu-item-sidebar" : ""}`}
        >
          {shortenItem(t("Tender Strategy"), sidebar)}
        </NavLink>
      ) : null}
      {auth.isSuperUser && sidebar ? (
        <NavLink
          onClick={() => menuActions()}
          to="/admin/edit_tenders"
          className={`menu-item ${sidebar ? "menu-item-sidebar" : ""}`}
        >
          {shortenItem(t("Edit Tenders"), sidebar)}
        </NavLink>
      ) : null}
      {auth.isSuperUser && sidebar ? (
        <NavLink
          onClick={() => menuActions()}
          to="/admin/vademecum"
          className={`menu-item ${sidebar ? "menu-item-sidebar" : ""}`}
        >
          {shortenItem(t("Summary"), sidebar)}
        </NavLink>
      ) : null}
      {auth.isSuperUser && sidebar ? (
        <NavLink
          onClick={() => menuActions()}
          to="/admin/list_users"
          className={`menu-item ${sidebar ? "menu-item-sidebar" : ""}`}
        >
          {shortenItem(t("List Users"), sidebar)}
        </NavLink>
      ) : null}
    </React.Fragment>
  );
};
