import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../Services/auth";

export function CookiePopup() {
  const cookieName = `${process.env.REACT_APP_COOKIE_OPTIONS_KEY}_${process.env.REACT_APP_VERSION}`;
  const [cookieState, setCookieState] = React.useState(JSON.parse(localStorage.getItem(cookieName)));
  const auth = useAuth();
  const [tokensAndFirstTime] = React.useState(auth.getTokenAndFirstTimeFromLocalStorage());
  const [modal, setModal] = React.useState(false);

  React.useEffect(() => {
    if (!tokensAndFirstTime?.first_time) {
      if (!cookieState) {
        setTimeout(() => {
          setModal(true);
        }, 4000);
      } else if (cookieState.optionSelected === 0) {
      } else {
        if (!cookieState.cookiesEnabled) {
          window["ga-disable-G-HD1WNFNYCE"] = true;
        } else {
          window["ga-disable-G-HD1WNFNYCE"] = false;
        }
      }
    }

    return () => {};
  }, [cookieState]);

  const { t } = useTranslation();
  return (
    <Modal
      isOpen={modal}
      role="dialog"
      autoFocus={true}
      centered={true}
      className=""
      tabIndex="-1"
      zIndex="11"
      backdrop={true}
      toggle={() => {
        setModal(!modal);
      }}
    >
      <div className="modal-content">
        <ModalHeader
          tag="h1"
          className="ta-modals-header"
          toggle={() => {
            setModal(!modal);
          }}
        >
          {t("Cookie Options")}
        </ModalHeader>
        <ModalBody>
          <div className="th-grey-text">
            {t(
              "We only keep cookies essential to the site's functionality. To improve our service, we collect some basic (anonymous) analytics, which you may disable here."
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="tc-full-width-small"
            type="button"
            color="primary"
            onClick={() => {
              setModal(false);
              const cookieState = { optionSelected: 1, cookiesEnabled: true };
              setCookieState(cookieState);
              localStorage.setItem(cookieName, JSON.stringify(cookieState));
            }}
          >
            {t("Keep Analytics")}
          </Button>
          <Button
            className="tc-full-width-small"
            type="button"
            color="secondary"
            onClick={() => {
              setModal(false);
              const cookieState = { optionSelected: 1, cookiesEnabled: false };
              setCookieState(cookieState);
              localStorage.setItem(cookieName, JSON.stringify(cookieState));
            }}
          >
            {t("Disable Analytics")}
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
}
