import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
import { immer } from "zustand/middleware/immer";
import { persist, createJSONStorage } from "zustand/middleware";
import { mergeDeepLeft } from "ramda";
import { get, set, del } from "idb-keyval"; // can use anything: IndexedDB, Ionic Storage, etc.
import { current } from "immer";

const storage = {
  getItem: async (name) => {
    //devDebug(name, "has been retrieved");
    return (await get(name)) || null;
  },
  setItem: async (name, value) => {
    //devDebug(name, "with value", value, "has been saved");
    await set(name, value);
  },
  removeItem: async (name) => {
    //devDebug(name, "has been deleted");
    await del(name);
  },
};

// define the initial state
const initialState = {
  cpvStatSearch: "",
  awardsDropDownOpen: false,
  showHelpVideoModal: false,
  showUnsavedPopup: false,
  showMultilanguageVideo: false,
  showUpgradePopup: false,
  showNoResultsMessage: false,
  finishedCounter: 0,
  nutsSearchFromDashboardClick: "",
  showCompanyModal: false,
  companyIDToGet: 0,
  companyNameToGet: "",
  tenderingAuthorityNameToGet: "",
  showTAModal: false,
  tenderingAuthorityIDToGet: 0,
  awardsTableConstraintType: "",
  awardsTableConstraintID: null,
  awardsTableConstraintName: "",
  isDark: false,
  input: "",
  hideListNutsSearch: true,
  applyModal: false,
  setApplyModalContent: "apply",
  deduplicateCallTime: {},
  showDislikeWarningModal: false,
  dislikeWarningModalShown: false,

};

export const useComponentsStore = createWithEqualityFn(
  persist(
    immer((set, get) => ({
      ...initialState,
      setHasHydrated: (state) => {
        set({
          _hasHydrated: state,
        });
      },
      setIsDark: (payload) =>
        set((state) => {
          state.isDark = payload;
        }),
      // ------------------- MODALS --------------
      setShowDislikeWarningModal: (showDislikeWarningModal) =>
        set((state) => {
          state.showDislikeWarningModal = showDislikeWarningModal
        }),
      setDislikeWarningModalShown: (dislikeWarningModalShown) =>
        set((state) => {
          state.dislikeWarningModalShown = dislikeWarningModalShown
        }),
      // ------------------- UTILITY FUNCTIONS --------------

      setDeduplicateCallTime: (key, time) =>
        set((state) => {
          state.deduplicateCallTime[key] = time
        }),
      // ------------------- VIEW / EDIT TENDER --------------
      setApplyModalContent: (applyModalContent) =>
        set((state) => {
          state.applyModalContent = applyModalContent;
        }),
      setApplyModal: (applyModal) =>
        set((state) => {
          state.applyModal = applyModal;
        }),
      setInput: (payload) =>
        set((state) => {
          state.input = payload;
        }),
      setHideListNutsSearch: (payload) =>
        set((state) => {
          state.hideListNutsSearch = payload;
        }),
      helpVideoAction: (payload) =>
        set((state) => {
          state.showHelpVideoModal = payload;
        }),
      setShowCompanyModal: (payload) =>
        set((state) => {
          state.showCompanyModal = payload;
        }),
      setAwardsDropDownOpen: (payload) =>
        set((state) => {
          state.awardsDropDownOpen = payload;
        }),
      setCompanyIDToGet: (payload) =>
        set((state) => {
          state.companyIDToGet = payload;
        }),
      setCompanyNameToGet: (payload) =>
        set((state) => {
          state.companyNameToGet = payload;
        }),
      setTenderingAuthorityNameToGet: (payload) =>
        set((state) => {
          state.tenderingAuthorityNameToGet = payload;
        }),
      setShowTAModal: (payload) =>
        set((state) => {
          state.showTAModal = payload;
        }),
      setAwardsTableConstraint: (type, id, name) =>
        set((state) => {
          state.awardsTableConstraintType = type;
          state.awardsTableConstraintID = id;
          state.awardsTableConstraintName = name;
        }),
      setTenderingAuthorityIDToGet: (payload) =>
        set((state) => {
          state.tenderingAuthorityIDToGet = payload;
        }),
      setShowUnsavedPopup: (payload) =>
        set((state) => {
          state.showUnsavedPopup = payload;
        }),
      multiLanguageVideoAction: (payload) =>
        set((state) => {
          state.showMultilanguageVideo = payload;
        }),
      setShowUpgradePopup: (payload) =>
        set((state) => {
          state.showUpgradePopup = payload;
        }),
      setStatSearch: (payload) =>
        set((state) => {
          state.cpvStatSearch = payload;
        }),
      setShowNoResultsMessage: (payload) =>
        set((state) => {
          state.showNoResultsMessage = payload;
        }),
      setFinishedCounter: (payload) =>
        set((state) => {
          state.finishedCounter += 1;
        }),
      setNutsSearchFromDashboardClick: (payload) =>
        set((state) => {
          state.nutsSearchFromDashboardClick = payload;
        }),
      resetState: () =>
        set((state) => {
          state.dislikeWarningModalShown = false
        }),
    })),
    {
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(([key]) => !["_hasHydrated",
            "cpvStatSearch",
            "awardsDropDownOpen",
            "showHelpVideoModal",
            "showUnsavedPopup",
            "showMultilanguageVideo",
            "showUpgradePopup",
            "showNoResultsMessage",
            "finishedCounter",
            "nutsSearchFromDashboardClick",
            "showCompanyModal",
            "companyIDToGet",
            "companyNameToGet",
            "tenderingAuthorityNameToGet",
            "showTAModal",
            "tenderingAuthorityIDToGet",
            "awardsTableConstraintType",
            "awardsTableConstraintID",
            "awardsTableConstraintName",
            "isDark",
            "input",
            "hideListNutsSearch",
            "applyModal",
            "setApplyModalContent",
            "deduplicateCallTime",
          ].includes(key))
        ),
      name: "componentsStore",
      storage: createJSONStorage(() => storage),
      version: process.env.REACT_APP_VERSION,
      merge: (persistedState, currentState) => mergeDeepLeft(persistedState, currentState),
      onRehydrateStorage: (state) => {
        // devDebug("cpvStore -> hydration starts, state: ", state);
        return (state, error) => {
          if (error) {
            // devDebug("cpvStore -> an error happened during hydration", error);
          } else {
            // devDebug("cpvStore -> hydration finished, state: ", state);
            state.setHasHydrated(true);
          }
        };
      },
    }
  ),
  shallow
);
