import { useAuth } from "../../../Services/auth";
import devDebug from "../../../Helpers/debug";
import { useMyTendersStore } from "../../../Stores/myTenders";
import Icon from "@mdi/react";
import { mdiStar, mdiStarOutline, mdiThumbDown, mdiThumbDownOutline } from "@mdi/js";
import { getFavourites, getDislikes } from "../../Protected - My Tenders/myTenderUtility";
import { useComponentsStore } from "../../../Stores/componentsStore";

const favouriteTender = (auth, id, del) => {
  const controller = new AbortController();

  let fn = auth.apiService.axiosPOST;

  if (del) {
    fn = auth.apiService.axiosDELETE;
  }

  fn(
    del ? `user/favorites/${id}` : `user/favorites/`, //endpoint
    controller.signal, //signal
    favouriteTenderSuccess, //successFn
    auth.newErrorHandler, //error AlerterFn
    "There was an error changing the favourite status of the tender", //error message
    favouriteTenderError, //errorFn (gets error back)
    {
      section: "Tender List Item -> Favourite -> favouriteTender",
      id: id,
      payload: { tender_id: id },
      auth: auth,
    } //data (gets handed back to success fn)
  );
};

const favouriteTenderSuccess = (resp, data) => {
  useMyTendersStore.getState().setFavouriteInProgressIDs(data.id);
  getFavourites(data.auth);
  getDislikes(data.auth);
};
const favouriteTenderError = (err, data) => {
  devDebug("There was an error favouriting the tender: ", err);
  devDebug("There was an error favouriting the tender, this is data: ", data);
  useMyTendersStore.getState().setFavouriteInProgressIDs(data.id);
};
const dislikeTender = (auth, id, del) => {
  const controller = new AbortController();

  let fn = auth.apiService.axiosPOST;

  if (del) {
    fn = auth.apiService.axiosDELETE;
  }

  fn(
    del ? `user/dislikes/${id}` : `user/dislikes/`, //endpoint
    controller.signal, //signal
    dislikeTenderSuccess, //successFn
    auth.newErrorHandler, //error AlerterFn
    "There was an error changing the dislike status of the tender", //error message
    dislikeTenderError, //errorFn (gets error back)
    {
      section: "Tender List Item -> Dislike -> dislikeTender",
      id: id,
      payload: { tender_id: id },
      auth: auth,
    } //data (gets handed back to success fn)
  );
};

const dislikeTenderSuccess = (resp, data) => {
  useMyTendersStore.getState().setDislikeInProgressIDs(data.id);
  getDislikes(data.auth);
  getFavourites(data.auth);
};
const dislikeTenderError = (err, data) => {
  devDebug("There was an error disliking the tender: ", err);
  devDebug("There was an error disliking the tender, this is data: ", data);
  useMyTendersStore.getState().setDislikeInProgressIDs(data.id);
};

export default function FavouriteTender({ bookmarked, disliked, id }) {
  const auth = useAuth();

  const [favouriteInProgressIDs, setFavouriteInProgressIDs, dislikeInProgressIDs, setDislikeInProgressIDs] =
    useMyTendersStore((state) => [
      state.favouriteInProgressIDs,
      state.setFavouriteInProgressIDs,
      state.dislikeInProgressIDs,
      state.setDislikeInProgressIDs,
    ]);

  const [setShowDislikeWarningModal, setDislikeWarningModalShown, dislikeWarningModalShown] = useComponentsStore(
    (state) => [state.setShowDislikeWarningModal, state.setDislikeWarningModalShown, state.dislikeWarningModalShown]
  );

  return (
    <div className="d-flex tc-flex-gap-5px align-items-center">
      {favouriteInProgressIDs[id] ? (
        <div className="mt-1 ms-1 tc-spinner-small "> </div>
      ) : (
        <Icon
          className="cursor-pointer tc-bookmarked-star-colour"
          path={bookmarked ? mdiStar : mdiStarOutline}
          size={1.5}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setFavouriteInProgressIDs(id);
            favouriteTender(auth, id, bookmarked);
          }}
        />
      )}
      {dislikeInProgressIDs[id] ? (
        <div className="mt-1 ms-1 tc-spinner-small "> </div>
      ) : (
        <Icon
          path={disliked ? mdiThumbDown : mdiThumbDownOutline}
          className="cursor-pointer"
          size={1.4}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setDislikeInProgressIDs(id);
            dislikeTender(auth, id, disliked);
            if (!dislikeWarningModalShown && !disliked) {
              setShowDislikeWarningModal(true);
              setDislikeWarningModalShown(true);
            }
          }}
        />
      )}
    </div>
  );
}
